import React, { useState, useEffect, useRef } from "react";
import { object, bool, array, func } from "prop-types";
import { Link } from "@quintype/components";
import SectionShowMoreStories from "../../molecules/section-show-more-stories";
import CardImageType8 from "../../molecules/card-image-type-8";
import TimeStamp from "../../atoms/time-stamp";
import StarShowHide from "../../atoms/star-show-hide";
import { Suurtaulu } from "../../atoms/suurtaulu";
import { ShowMoreBtn } from "../../atoms/show-more-btn";
import { postTags } from "../../utils/post-tags";
import "./tag.m.css";
import { BookmarkIcon } from "../../molecules/bookmark-icon";
import { CommentIcon } from "../../molecules/comment-icon";
import SideBar from "../../molecules/sidebar";
import BookmarkPopup from "../../molecules/bookmark-popup";
import useStorage from "../../../hook/useStorage";
import { PopoverElement } from "../../atoms/popover-element";
import { generateProfileHref } from "../../utils/generate-profile-href";
import { generatePaperValues } from "../../utils/generate-paper-values";
import CardImageType5 from "../../molecules/card-image-type-5";

export const storiesDom = stories => {
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  return stories.map((story, index) => {
    const storyObj = story.story || story;
    /* getting url right regarding different papers */

    var paperValues = generatePaperValues(storyObj);
    function findByKey(key, value) {
      return (item, i) => item[key] === value;
    }

    const findParams = findByKey("domain-slug", "ilkka-pohjalainen"); // change here
    const positionOfPaper = storyObj.sections.findIndex(findParams);

    var parts = storyObj.slug.lastIndexOf("/");
    var storySlug = storyObj.slug.substring(parts + 1);

    // Check if there is section for that paper
    if (storyObj.sections[positionOfPaper]) {
      var splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/"); // split when slash
      var joinUrl = splitUrl.slice(3); // slice after third slug
      var finalUrl = joinUrl.join("/"); // join the remaining parts with slash
    }

    const isPublishedToday = new Date(storyObj["published-at"]).toISOString().split('T')[0] === today;

    if (isPublishedToday) {
      return (
        <React.Fragment key={index}>
          <CardImageType5 story={story} showSubHeadline={false} />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={index}>

          {story["hero-image-s3-key"] !== null ? (
            <div className="item" key={story.id}>
              <CardImageType8 story={story} showSubHeadline={false} page={"tag"} />
            </div>
          ) : (
            <div
              className="main-headline item"
              styleName={story["hero-image-s3-key"] ? "story-content" : "story-content-no-img"}
            >
              <Link
                href={
                  generateProfileHref(storyObj)
                }
              >
                <div styleName="wrapper-inside" className="wrapper-inside">
                  <h2 styleName="" className="headline-layout4 main-headline-layout1 layout4">
                    <span styleName="span-newest">
                      {story.metadata && story.metadata["pääaihe"] && `${story.metadata["pääaihe"]} | `}
                    </span>
                    {story.headline}
                  </h2>
                  <div styleName="section-time-wrapper">
                    <div className="time-stamp-layout1">
                      <TimeStamp timeStamp={story["published-at"]} prefix={paperValues.paperName} />
                    </div>
                    {(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ") ? null : <StarShowHide accessType={paperValues.accessType} />}
                    <CommentIcon storyObj={storyObj} />
                    <BookmarkIcon user_flag="bm_user_flag" storyObj={storyObj} showBookmark={true} />
                  </div>
                </div>
              </Link>
            </div>
          )}
        </React.Fragment>
      );
    }
  });
};

const TagPage = props => {
  const { data } = props;
  const [tagStories, setTagStories] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [show, setShow] = useState(true)
  const [op, setOp] = useState(true);
  const [isBmTag, setIsBmTag] = useState(false)
  const [alreadyBMtags, setAlreadyBMtags] = useState(true);
  const [checkUser] = useStorage();
  const tag = data.tag;
  const [storiesFetched, setStoriesFetched] = useState(false);

  useEffect(() => {
    async function fetchAPI() {
      const stories = await fetch(`/api/v1/advanced-search?tag-name-lower=${tag.name}&limit=300&section-id=${data.sectionIds}&sort=latest-published`)
      const response = await stories.json();
      const resultData = response.items;
      setTagStories(resultData);
      setStoriesFetched(true); // Set storiesFetched to true after fetching stories
    }
    fetchAPI();

  }, [])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    } else {
      setTimeout(function () {
        const storedTags = JSON.parse(localStorage.getItem("tags") || "[]");
        const storedTagsLower = storedTags.map((tag) => tag.toLowerCase());
        if (storedTags.includes(decodeURIComponent(tag.name)) === true || storedTagsLower.includes(tag.name.toLowerCase())) {
          setIsBmTag(true)
        }
      }, 720);
    }

  }, [])

  const storiesWithTag = [...tagStories || null];
  const storiesWithTag30 = storiesWithTag.slice(0, 30);
  const [storyLimit, setStoryLimit] = useState(30);
  const onItemsToLoadClick = () => setStoryLimit(storyLimit + 10);
  return (
    <div styleName="top-container">
      <div styleName="container">
        <div styleName="main">
          <div styleName="ad-container">
            <div styleName="mob-ad">
              <div id="sas_63333" data-placement-id="paraatipaikka-mobile"></div>
            </div>
            <div styleName="dsktop-ad">
              <div id="sas_63219" data-placement-id="paraatipaikka-desktop"></div>
            </div>
          </div>
          <div styleName="ad-container">
            <div styleName="mob-ad">
              <div id="sas_112313" data-placement-id="paraatipaikka-2-mobile"></div>
            </div>
            <div styleName="dsktop-ad">
              <div id="sas_112312" data-placement-id="paraatipaikka-2-desktop"></div>
            </div>
          </div>
        </div>
        <div styleName="lazy-pop">
          <div styleName="section-content">
            <div styleName="section-top">
              <div styleName="section-title-tag">
                <div styleName="section-title">{decodeURIComponent(data.title)}</div>
                {isBmTag && alreadyBMtags ? <div onClick={(e) => {
                  if (checkUser) {
                    e.preventDefault();
                    postTags(e, decodeURIComponent(tag.name), false)
                    setAlreadyBMtags(false)
                    setShowPopup(false)
                  } else {
                    console.log("user need to be logged in to perform this action");
                    setShowPopup(true)
                  }
                }} styleName="tag-btn" className="tag-btn">
                  {checkUser ?
                    (!show ? <button styleName="add-tag tag-btn-wrapper" className="tag-btn-wrapper add-tag" title="Seuraa aihetta">SEURAA <i className={`ri-add-line`}></i></button>
                      : <button styleName="tag-checked tag-btn-wrapper" className="tag-btn-wrapper tag-checked" title="Lopeta aiheen seuraaminen">SEURAA <i className={`ri-check-line`}></i></button>)
                    : (<button styleName="add-tag tag-btn-wrapper" className="tag-btn-wrapper add-tag" title="Seuraa aihetta"><div>SEURAA <i className={`ri-add-line`}></i></div></button>)}
                </div> :
                  <div onClick={(e) => {
                    if (checkUser) {
                      e.preventDefault();
                      setShow(!show)
                      setOp(!op)
                      postTags(e, decodeURIComponent(tag.name), op)
                      setShowPopup(false)
                    } else {
                      console.log("user need to be logged in to perform this action");
                      setShowPopup(true)
                    }
                  }} styleName="tag-btn" className="tag-btn">
                    {checkUser ?
                      (show ? <button className="tag-btn-wrapper add-tag" title="Seuraa aihetta"><PopoverElement showContents={false} user_flag="tag_user_flag" anchor="follow-ri-add-line" /></button>
                        : <button className="tag-btn-wrapper tag-checked" title="Lopeta aiheen seuraaminen"><PopoverElement user_flag="tag_user_flag" anchor="follow-ri-check-line" /></button>)
                      : (<button styleName="add-tag tag-btn-wrapper" className="tag-btn-wrapper add-tag" title="Seuraa aihetta">SEURAA <i className={`ri-add-line`}></i></button>)}
                  </div>
                }
              </div>
              {showPopup ? <BookmarkPopup closePopup={() => setShowPopup(false)} feature={"save-tag"} /> : ""}
              <div styleName="section-line"></div>
            </div>
            <div styleName="bottom-stories-with-sidebar">
              {(
                storiesFetched ? (
                  storiesWithTag.length > 0 ? (
                    storiesDom(storiesWithTag30)
                  ) : (
                    <h2 styleName="no-story-headline">Ei artikkeleita...</h2>
                  )
                ) : (
                  <div styleName="custom-loader"></div>
                )
              )}
              {<SectionShowMoreStories stories={storiesWithTag} startIndex={30} itemsToLoad={storyLimit} page={"tag"} />}
              {
                (storiesWithTag.length > 30 && (storiesWithTag.length >= storyLimit + 1)) ?
                  <ShowMoreBtn loadMore={onItemsToLoadClick} btnName="NÄYTÄ LISÄÄ" /> :
                  null
              }
            </div>
          </div>
          <SideBar />
        </div>
      </div>
      <Suurtaulu />
    </div>
  );
};

TagPage.propTypes = {
  data: object,
  onLoadMore: func,
  stories: array,
  noMoreStories: bool
};

export { TagPage };
