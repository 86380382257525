import React, { useEffect, useState } from "react";
import "./city-tags.m.css";
import Tags from "../../molecules/tags";
import get from "lodash/get";
import { connect } from "react-redux";
import { string } from "prop-types";
import { updateUserFlag } from "../../../hook/update-user-flag";
import { deleteUserFlag } from "../../../hook/delete-user-flag";

const CityTagsBase = (props) => {
  const tags = [
    { name: 'Kurikka', slug: 'kurikka' },
    { name: 'Laihia', slug: 'laihia' },
    { name: 'Lapua', slug: 'lapua' },
    { name: 'Mustasaari', slug: 'mustasaari' },
    { name: 'Seinäjoki', slug: 'seinäjoki' },
    { name: 'Vaasa', slug: 'vaasa' },
  ];

  const [isChecked, setIsChecked] = useState(true);
  const [wListed, setwListed] = useState(false);
  const handleToggle = async() => {
    isChecked&&await updateUserFlag("toggle_tags")
    !isChecked&&await deleteUserFlag("toggle_tags")
    setIsChecked(!isChecked);
  };

    useEffect(() => {
      if (typeof window === 'undefined') {
        return;
      } else {
        const listed = JSON.parse(window.localStorage.getItem("ipUser"));
        setwListed(listed)
      }
    }, []);


  useEffect(()=>{
    const fetchTagsToggle = async () => {
      try {
        const response = await fetch(`/profile/flag/getByName`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ flag_name: "has_personalized_content_disabled" }),
        });
        const jsonResponse = response && await response.json();
        if (jsonResponse.data === "1") {
          setIsChecked(false);
        }else{
          setIsChecked(true);
        }
      } catch (error) {
        console.log(error)
      }
  };
  fetchTagsToggle();
  },[isChecked])

  return (
    (!wListed && (props.pageType === "home-page" || props.pageType === "banner-page")) && <div styleName="city-tags-container" className="city-tags-container">
        <p styleName="city-tags-container-header">Ota seurattavaksi kiinnostavimmat paikkakunnat</p>
      <div styleName="toggle-container">
        <label styleName="toggle-label">
          <span styleName="toggle-text">Piilota</span>
          <input type="checkbox" checked={isChecked} onChange={handleToggle} />
          <div styleName="slider-container"><span styleName={isChecked ? 'slider slider-on' : 'slider slider-off'}></span></div>
          <span styleName="toggle-text">Näytä</span>
        </label>
      </div>
      <p styleName="city-tags-container-text">Valitse aiheita seurattavaksi klikkaamalla sanan perässä olevaa plus-merkkiä.</p>
      <div styleName="city-tags-bottom">
          <div styleName={isChecked ? "city-tag-btns-container" : "city-tag-btns-container tag-btns-off"}>
            <Tags tags={tags} place={"story-page"} />
          </div>
          <div styleName={isChecked ? "city-tags-cta" : "city-tags-cta tag-btns-off"}>
            <button styleName="to-followed-tags-page-btn" onClick={() => window.location.href = "/seuraamani-aiheet"}>
              Katso oma listasi
            </button>
          </div>
        </div>
    </div>
  );
};


function mapStateToProps(state) {
  return {
      pageType: get(state, ["qt", "config", "page-type"], ""),
  };
}

CityTagsBase.propTypes = {
  pageType: string
};

const CityTags = connect(mapStateToProps, () => ({}))(CityTagsBase);
export default CityTags;
