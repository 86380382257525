import React, { useEffect, useState } from "react";
import LazyEagerImage from "../lazy-eager";
import "./home-page-box.m.css";

function HomePageBox() {

  const [isDisplayed, setIsDisplayed] = useState(false);
  //randomnumber saved to state variable to prevent re-rendering of the component
  const [randomNumber, setRandomNumber] = useState(Math.random());

  // set display to true after the component has mounted. This is to prevent flashing of the component.
  useEffect(() => {
    let isMounted = true;

    setTimeout(() => {
      setIsDisplayed(true);
    }, 0);
    return () => {
      isMounted = false;
    };
  }, [])

  const renderCommentBox = () => (
    <div styleName="comment-home-page-box-container">
      <div styleName="comment-home-page-box-speech">
        <div styleName="comment-home-page-box-header">
          <p>Osallistu, keskustele, pohdiskele!</p>
        </div>
        <div className="comment-home-page-box-text-container">
          Oletko huomannut, että kommentointi on nyt mahdollista Ilkka-Pohjalaisen verkkosivuilla?
        </div>
      </div>
    </div>
  );

  const renderGamesBox = () => (
    <div styleName="games-container">
      <div styleName="text-container">
        <p>Joka päivä uusia ristikoita ja sanapelejä!</p>
        <div styleName="games-cta">
          <button styleName="to-games-btn" onClick={() => window.location.href = "/pelit"}>
            Peleihin
          </button>
        </div>
      </div>
      <div styleName="icon-container">
        <div styleName="sana-icon">
          <img src="/assetify/Sana-icon-vihrea.png" alt="Sana! Logo" className="sana icon" />
        </div>
        <div styleName="sanaristikko-icon">
          <img src="/assetify/Sanaristikko-icon-liila.png" alt="Sanaristikko Logo" className="sanaristikko icon" />
        </div>
        <div styleName="hoyryristikko-icon">
          <img src="/assetify/Hoyryristikko-icon-liila.png" alt="Hoyryristikko Logo" className="hoyryristikko icon" />
        </div>
      </div>
    </div>
  );

  const renderIppeBox = () => (
    <a styleName="ippe-container" href="/urheilu-ippe" aria-label="I-P tulospalvelu - paikalliset urheilutulokset nopeasti">
      <div styleName="ippebanner">
        <LazyEagerImage
        src="/assetify/I-P_tulospalvelu-banneri-urheilusivu-720x115.png"
        srcSet="/assetify/I-P_tulospalvelu-banneri-urheilusivu-720x115.png 1023w,
                /assetify/I-P-tulospalvelu-banneri-etusivu-648x115.png"
        alt="Tulospalveluun"/>
      </div>
      <div styleName="text-container small-screens-only">
        <p>Tulospalvelusta paikalliset urheilutulokset nopeasti!</p>
        <div styleName="games-cta">
          <button styleName="to-games-btn">
            Tulospalveluun
          </button>
        </div>
      </div>
    </a>
  );

  return (
    <React.Fragment>
      {/* Conditionally render the games box, comment box, or ippe box based on the random number. Render only when isDisplayed is true to prevent flickering */}
      {isDisplayed ? (
        randomNumber <= 0.33 ? renderCommentBox() :
        randomNumber <= 0.66 ? renderGamesBox() :
        renderIppeBox()
      ) : (
        <div styleName="homepagebox-placeholder"></div>
      )}
    </React.Fragment>
  )
}

export default HomePageBox;

