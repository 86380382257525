//this is ippe banner for urheilu page

import React from 'react';
import './ippe-banner.m.css';
import LazyEagerImage from '../lazy-eager';

const IppeBanner = () =>
  <a styleName="ippebanner" aria-label="I-P tulospalvelu - paikalliset urheilutulokset nopeasti" href="/urheilu-ippe">
    <LazyEagerImage
    src="/assetify/I-P_tulospalvelu-banneri-urheilusivu-720x115.png"
    srcSet="/assetify/I-P_tulospalvelu-banneri-urheilusivu-720x115.png 1023w,
            /assetify/I-P_tulospalvelu-banneri-urheilusivu-988x115.png"
    alt="Tulospalveluun"/>
  </a>

export {IppeBanner}
