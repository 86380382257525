/* eslint-disable import/extensions */

import "./games.m.css";
import { BullsGames } from "../../atoms/bulls-games";
import React, { useEffect, useState } from "react";
import { object } from "prop-types";
import LoginFormBase from "../../atoms/login-forms/login-form";
import useStorage from "../../../hook/useStorage";
import Uusimmat from "../../story-templates/uusimmat";
import { Suurtaulu } from "../../atoms/suurtaulu";

const GamePage = React.memo(() => {
  const [checkUser, activeProducts] = useStorage();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [wListed, setWlisted] = useState(false);
  const [isMiniProductActive, setIsMiniProductActive] = useState(false);
  const [gamePageSlug, setGamePageSlug] = useState();

  useEffect(() => {
    let isMounted = true;
    if (typeof window === 'undefined') {
      return;
    } else {
      setGamePageSlug(gamePageSlug);
      if (isMounted) {
        const listed = JSON.parse(window.localStorage.getItem("ipUser"));
        setWlisted(listed);
        if (!listed) {
        }
      }
      const miniProductActive = JSON.parse(localStorage.getItem("miniProduct"));
      setIsMiniProductActive(miniProductActive);
    };
    setTimeout(() => {
      setIsDisplayed(true);
    }, 1000);
    return () => {
      isMounted = false;
    };
  }, [])

  return (
    <React.Fragment>
      <div styleName="top-container" className="top-container">
        <div styleName="main-container">
          <div styleName="main">
            <div styleName="ad-container">
              {/* PARAATI mobiili*/}
              <div styleName="mob-ad">
                <div id="sas_63333" data-placement-id="paraatipaikka-mobile"></div>
              </div>
              {/* PARAATI deskari ja tabletti*/}
              <div styleName="dsktop-ad">
                <div id="sas_63219" data-placement-id="paraatipaikka-desktop"></div>
              </div>
            </div>
          </div>
          <div styleName="games-container">
            {checkUser ? (<div styleName="games-left-container">
              <h1 styleName="top-headline">Pelit</h1>
              <p styleName="text-games">Tervetuloa Ilkka-Pohjalaisen pelisivulle! Tarjoamme ajanvietteeksesi joka päivä uusia ristikoita ja sanapelejä.</p>
              <br />
              <BullsGames />
              <br />
              <h2 styleName="headline">Sana!</h2>
              <p styleName="text-games">Ratkaistavaksesi aukeaa yksi sana joka päivä. Vasemman ylänurkan kysymysmerkistä löydät peliohjeet ja oikean ylänurkan tilasto-kuvakkeesta oman aiemman pelimenestyksesi.</p>
              <h2 styleName="headline">Sanaristikko</h2>
              <p styleName="text-games">Päivän ristikon lisäksi löydät arkistosta kuusi edellistä sanaristikkoa. Oikean ylänurkan kuvakkeista saat apua ristikon täyttöön. Voit vaihdella pysty- ja vaakasuuntaisten sanojen välillä klikkaamalla samaa ruutua toisen kerran.</p>
              <h2 styleName="headline">Höyryristikko</h2>
              <p styleName="text-games">Päivän ristikon lisäksi löydät arkistosta kuusi edellistä höyryristikkoa. Oikean ylänurkan kuvakkeista saat apua ristikon täyttöön. Kun klikkaat sanaa, ilmestyy vihje ristikon yläpuolelle. Voit vaihdella pysty- ja vaakasuuntaisten sanojen välillä klikkaamalla samaa ruutua toisen kerran. Tietokoneella voit lisäksi liikuttaa ristikkoa sivusuunnassa alareunan vierityspalkista.</p>
            </div>) : (<div styleName="games-login-container" className="games-login-container">
              {isDisplayed && <>
                <h1 styleName="top-headline">Pelit</h1>
                <span styleName="text-games">Kirjaudu sisään Ilkka-Pohjalaisen pelisivulle.</span></>}
              {isDisplayed ? <LoginFormBase /> : <div styleName="custom-loader"></div>}
            </div>)}          </div>
        </div>
        <Suurtaulu />
      </div>
    </React.Fragment >
  );
})
GamePage.propTypes = {
  config: object
};

export { GamePage };
