/* eslint-disable import/extensions */

import PT from "prop-types";
import React, { useEffect, useState } from "react";
import sortBy from 'lodash/sortBy';
import { SearchBox } from "../../molecules/search-box";
import { LoadmoreButton } from "../../atoms/Loadmore-btn";
import LoadMoreSearchResults from "../../molecules/load-more-search-results";
import { Suurtaulu } from "../../atoms/suurtaulu";
import "./search.m.css";
import SideBar from "../../molecules/sidebar";

const SearchPage = props => {
  const { data } = props;
  const [orderedStories, setOrderedStories] = useState([]);
  const [storiesData, setStoriesData] = useState();
  const [initialData, setInitialData] = useState([]);
  const [storiesFetched, setStoriesFetched] = useState(false);

  useEffect(() => {
    const callAPI = async () => {
      const stories = await fetch(`/api/v1/advanced-search?q=${data.query}&limit=300&section-id=${data.sectionIds}`)
      const response = await stories.json();
      const res = response.items;
      const ordered = sortBy(res, [function (o) {
        return o["published-at"];
      }])
      const created = sortBy(res, [function (o) {
        return o["content-created-at"];
      }])
      const resultData = ordered.reverse();
      setOrderedStories(resultData);
      setInitialData(created)
      setStoriesFetched(true); // Set storiesFetched to true after fetching stories
    }
    callAPI();
  }, [])

  const queryhelper = data.query ? data.query.replace(/-/g, " ") : null;
  const [toggle, setToggle] = useState(false);
  const [byRelativity, setByRelativity] = useState(false);
  const [toggleBoth, setToggleBoth] = useState(false);
  const [dates, setDates] = useState(false);

  const sortByDate = () => {
    setToggle(true)
    setByRelativity(false)
    setToggleBoth(true)
    setDates(true)
  }

  const [storyLimit, setStoryLimit] = useState(20);
  const onItemsToLoadClick = () => setStoryLimit(storyLimit + 10);
  const sortByRelativity = async () => {
    const stories = await fetch(`/api/v1/advanced-search?q=${data.query}&limit=300&section-id=${data.sectionIds}`)
    const response = await stories.json();
    const resultData = response.items;
    setStoriesData(resultData);
    setByRelativity(true)
    setToggle(false)
    setToggleBoth(false)
    setDates(false)
  }

  return (
    <div styleName="top-container">
      <div styleName="main">
        <div styleName="ad-container">
          <div styleName="mob-ad">
            <div id="sas_63333" data-placement-id="paraatipaikka-mobile"></div>
          </div>
          <div styleName="dsktop-ad">
            <div id="sas_63219" data-placement-id="paraatipaikka-desktop"></div>
          </div>
        </div>
        <div styleName="ad-container">
          <div styleName="mob-ad">
            <div id="sas_112313" data-placement-id="paraatipaikka-2-mobile"></div>
          </div>
          <div styleName="dsktop-ad">
            <div id="sas_112312" data-placement-id="paraatipaikka-2-desktop"></div>
          </div>
        </div>
      </div>
      <div styleName="wrapper">
        <div styleName="result-body">
          <div styleName="search-content">
            <h1 styleName="headline">{`Hae sivulta`} </h1>
            <div styleName="search-box"><SearchBox /></div>
            {queryhelper != null && <div>
              <div styleName="search-sorting">
                <div styleName="sort-firstrow">
                  <div styleName="sort-heading">Hakutulokset:</div>
                  <div styleName="sort-count">
                    <div styleName="count-query">"{queryhelper}"</div>
                    <div styleName="count-total">
                      {byRelativity ?
                        storiesData.length :
                      orderedStories.length || "0"}
                    </div>
                    <div styleName="count-hits">osumaa</div>
                  </div>
                </div>
              </div>
              {storiesFetched ?
              ((initialData.length > 0 && orderedStories.length > 0) ? <LoadMoreSearchResults
                dates={dates}
                toggleBoth={toggleBoth}
                byRelativity={byRelativity}
                storiesData={storiesData}
                data={data}
                orderedStories={orderedStories}
                storyLimit={storyLimit}
                isOrdered={toggle}
                sortByDate={() => sortByDate()}
                sortByRelativity={() => sortByRelativity()}
                initialData={initialData}
              /> : (
                <h3 styleName="noresult-headline">Haullasi ei löytynyt tuloksia, muuta hakusanaa ja kokeile uudelleen.</h3>
              ))
            :
            (
              <div styleName="custom-loader"></div>
            )}
              {orderedStories.length > 20 ?
                <div styleName="loadmore">
                  <LoadmoreButton
                    onClick={() => onItemsToLoadClick()}
                    btnText="Näytä lisää" />
                </div> :
              null}
            </div>}
          </div>
          <SideBar />
        </div>
        <Suurtaulu />
      </div>
    </div>
  );
};

SearchPage.propTypes = {
  data: PT.object
};

export {SearchPage};
