import React, { useEffect, useState } from "react";
import get from "lodash/get";
import useUrl from "../../../hook/useUrl";
import { Link } from "@quintype/components";
import CardImage from "../../atoms/card-image";

import { BookmarkIcon } from "../bookmark-icon";
import { CommentIcon } from "../comment-icon";
import StarShowHide from "../../atoms/star-show-hide";
import { object, number, array, bool } from "prop-types";
import { SectionCategoryTitle } from "../section-category-title";
import "./card-image-type-5.m.css";
import { generatePaperValues } from "../../utils/generate-paper-values";
import { generateProfileHref } from "../../utils/generate-profile-href";
import { getTimeStampLayout } from "../../utils/getTimeStampLayout";
import { generateHref } from "../../utils/generate-href";

const CardImageType5 = ({ story, showSection = false, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;
  const paaaihe = storyObj.metadata ? storyObj.metadata["pääaihe"] : undefined;
  const imgS3keys = storyObj["hero-image-s3-key"];
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [isTagPage, setIsTagPage] = useState(false);
  var paperValues = generatePaperValues(storyObj);
  const [_,currentUrlSlug] = useUrl();
  let accessType = storyObj.access;
  let domainSlug = "";
  switch (currentUrlSlug) {
    case "vaasalehti":
      domainSlug = "vaasa";
      accessType = "public";
      break;
    case "epari":
      domainSlug = "epari";
      accessType = "public";
      break;
    default:
      domainSlug = "ilkka-pohjalainen";
  }

  useEffect(() => {
    if (typeof window === "undefined") {
      console.log("Oops, `window` is not defined")

    } else {
      const decodeURI = decodeURIComponent(window.location.href);
      if (decodeURI==='http://localhost:3000/'
        || decodeURI==='https://yhteissivusto.fiare.qtstage.io/'
        || decodeURI==='https://imediat-web.fiare.qtstage.io/'
        || decodeURI==='https://ilkkapohjalainen.fi/') {
        setIsProfilePage(true);
      }
      if (decodeURI.includes("/topic/")){
        setIsTagPage(true);
      }
    }

  }, [])

  const liveIndicator = get(story, ["metadata","story-attributes","paivittyvajuttu", 0]);
  const getIcon = type => {
    if (type === "photo") {
      return (
        <div styleName="icon">
          <img src="/assetify/photo.svg" alt="photo" />
        </div>
      );
    } else if (type === "video") {
      return (
        <div styleName="icon">
          <i className="ri-movie-line"></i>
        </div>
      );
    }
  };
  function findByKey(key, value) {
    return (item, i) => item[key] === value;
  }
  const findParams = findByKey("domain-slug", domainSlug);
  const positionOfPaper = storyObj.sections.findIndex(findParams);
  var parts = storyObj.slug.lastIndexOf("/");
  var storySlug = storyObj.slug.substring(parts + 1);
  if (storyObj.sections[positionOfPaper]) {
    var splitUrl = storyObj.sections[positionOfPaper]["section-url"].split("/");
    var joinUrl = splitUrl.slice(3);
    var finalUrl = joinUrl.join("/");
  }
  return (
    <div styleName="wrapper" className="card-image-type-5">
      <Link
        href={(isProfilePage || isTagPage) ? generateProfileHref(storyObj) :
          generateHref(storyObj, currentUrlSlug)
        }
      >
        <div styleName="card-image-type-5" className="card-image-type-5-card">
          {showSection && <SectionCategoryTitle name={storyObj.sections[0].name} />}
          {imgS3keys !== null && (
            <div>
              <CardImage
                story={storyObj}
                defaultWidth={259}
                aspectRatio={[3, 2]}
                imageType="image3x2"
                widths={widths}
              />
              {(storyObj["story-template"] === "photo" || storyObj["story-template"] === "video") && (
                <div styleName="info" className="info">
                  {getIcon(storyObj["story-template"])}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="main-headline" styleName={imgS3keys ? "story-content" : "story-content-no-img"}>
          <div styleName="wrapper-inside" className="wrapper-inside">
            {(liveIndicator) ? (
              <div styleName="live-indicator" className="live-indicator"><span styleName="blink"></span>{liveIndicator}</div>
            ) : (
              null
            )}
            <h2 styleName="headline" className="headline-layout4 main-headline-layout1 layout4">
              <span styleName="span-newest">{paaaihe && `${paaaihe} | `}</span>
              {storyObj.headline}
            </h2>
          </div>
        </div>
      </Link>
      <div styleName="section-time-wrapper">
        {/*condition for showin "Ilkka-Pohjalainen" paper name only on tag pages*/}
        {isTagPage ? getTimeStampLayout("ilkka-pohjalainen-tag-page", paperValues, storyObj) : getTimeStampLayout(domainSlug, paperValues, storyObj)}
        {(paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ") ? null : <StarShowHide accessType={accessType} />}
        <CommentIcon storyObj={storyObj} />
        <BookmarkIcon user_flag="bm_user_flag" storyObj={storyObj} showBookmark={true} removeStory={false} />
      </div>
    </div>
  );
};

export default CardImageType5;

CardImageType5.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array,
  showSection: bool
};
