import React from "react";
import TimeStamp from "../atoms/time-stamp";

export const getTimeStampLayout = (domainSlug, paperValues, storyObj) => {
  if (
    domainSlug === "ilkka-pohjalainen" &&
    (paperValues.paperName === "Vaasa-lehti | " || paperValues.paperName === "Epari | ")
  ) {
    return (
      <div className="time-stamp-layout1">
        <TimeStamp timeStamp={storyObj["published-at"]} prefix={paperValues.paperName} />
      </div>
    );
    {/*condition for showing "Ilkka-Pohjalainen" paper name only on tag pages*/}
  } else if (domainSlug === "ilkka-pohjalainen-tag-page") {
    return (
      <div className="time-stamp-layout1">
        <TimeStamp timeStamp={storyObj["published-at"]} prefix={paperValues.paperName} />
      </div>
    );
  }
   else {
    return (
      <div className="time-stamp-layout1">
        <TimeStamp timeStamp={storyObj["published-at"]} />
      </div>
    );
  }
};
